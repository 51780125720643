import {Storage} from '@ionic/storage';
import {UserService} from './user.service';

export class WithPointsPage {
  public userBalance = false;

  constructor(
    storage: Storage,
    userService: UserService
  ) {
    storage.get('api_key').then((value) => {
      userService.getUser(true).then((user) => {
        this.userBalance = user.stats.balance;
      });
    });
  }
}
